<div class="container-fluid">
  <div class="card theme-card">
    <div class="card-header">
      <h3>Listado de bonos</h3>
    </div>
    <div class="card-body">
      <!-- Form section -->
      <div class="form-section">
        <form [formGroup]="fechaForm" (ngSubmit)="enviarFechas()" class="mb-4">
          <div class="form-group mb-3">
            <label for="fechaInicio" class="form-label">Fecha Inicial:</label>
            <input id="fechaInicio" type="date" formControlName="fechaInicio" class="form-control input-sm">
          </div>
          <div class="form-group mb-3">
            <label for="fechaFin" class="form-label">Fecha Final:</label>
            <input id="fechaFin" type="date" formControlName="fechaFin" class="form-control input-sm">
          </div>
          <div class="d-flex justify-content-end">
            <button type="submit" class="btn btn-custom-purple btn-lg">Enviar</button>
          </div>
        </form>
      </div>

      <!-- Separator -->
      <hr style="width: 100%; color: black; height: 1px; background-color:black;"/>

      <!-- Export Button -->

      <!-- Table section -->
      <div *ngIf="cargas.length > 0" class="table-responsive">
        <div class="d-flex justify-content-end mb-4">
          <button (click)="exportarComoExcel()" class="btn btn-success">
            Exportar a Excel
          </button>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Fecha de Carga</th>
              <th>Código Bono</th>
              <th>Saldo</th>
              <th>Valor</th>
              <th>Fecha Inicio Vigencia</th>
              <th>Fecha Fin Vigencia</th>
              <th>Estado</th>
              <th>Nombre Comercio</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let carga of cargas">
              <tr *ngFor="let bono of carga.bonos">
                <td>{{ carga.fechaCarga | date: 'dd/MM/yyyy' }}</td>
                <td>{{ bono.codigo }}</td>
                <td>{{ bono.saldo | currency }}</td>
                <td>{{ bono.valor | currency }}</td>
                <td>{{ bono.fechaInicioVigencia | date: 'dd/MM/yyyy' }}</td>
                <td>{{ bono.fechaFinVigencia | date: 'dd/MM/yyyy' }}</td>
                <td>{{ bono.nombreEstado }}</td>
                <td>{{ carga.usuarioCarga }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div *ngIf="cargas.length === 0" class="alert alert-warning">
        No existen registros para los datos ingresados.
      </div>
    </div>
  </div>
</div>