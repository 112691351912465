<div class="modal-header">
  <h4 class="modal-title">Detalles del Ticket {{ task.nroTicket }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Asunto:</label>
          <p>{{ task.asunto }}</p>
        </div>
      </div>
    </div>
    <!-- Información no editable -->
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Canal:</label>
          <p>{{ task.canal }}</p>
        </div>
      </div>

      <div class="col">
        <div class="form-group">
          <label>País:</label>
          <p>{{ task.pais }}</p>
        </div>
      </div>

      <div class="col">
        <div class="form-group">
          <label>Tienda:</label>
          <p>{{ task.tienda }}</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Categoría:</label>
          <p>{{ task.categoria }}</p>
        </div>
      </div>

      <div class="col">
        <div class="form-group">
          <label>Subcategoría:</label>
          <p>{{ task.subcategoria }}</p>
        </div>
      </div>
    </div>

    <!-- Campos editables -->
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="status">Estado:</label>
          <div class="custom-select" (click)="toggleOptions()">
            <div class="pill" [ngStyle]="{'background-color': stateColors[task.status]}">
              {{ task.status }}
            </div>
            <div class="status-options" *ngIf="optionsVisible">
              <div *ngFor="let status of statuses" class="pill-option"
                [ngStyle]="{'background-color': stateColors[status]}" (click)="selectStatus(status, $event)">
                <!-- Agrega $event aquí -->
                {{ status }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="form-group">
          <label for="responsible">Responsable:</label>
          <select id="responsible" [(ngModel)]="task.usuarioMesaAyuda.nombreCompleto" name="responsible" required>
            <option *ngFor="let user of users" [value]="user.nombreCompleto">{{ user.nombreCompleto }}</option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="priority">Prioridad:</label>
          <select id="priority" [(ngModel)]="task.prioridad" name="priority" required>
            <option value="baja">Baja</option>
            <option value="media">Media</option>
            <option value="alta">Alta</option>
            <option value="critica">Crítica</option>
          </select>
        </div>
      </div>
    </div>
    <!-- Sección de Archivos Adjuntos -->
    <div class="row" *ngIf="task.adjuntos?.length>0">
      <div class="col">
        <h5>Archivos Adjuntos del ticket</h5>
        <div class="attachments">
          <div class="row">


            <div class="attachments-container">
              <div class="attachment" *ngFor="let adjunto of task.adjuntos">
                <a [href]="adjunto" target="_blank">
                  <img [src]="adjunto" class="attachment-image" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <!-- Sección de comentarios -->
    <div *ngFor="let comment of comments" class="comment">
      <!-- Avatar y contenido del comentario -->
      <div class="author-avatar" [ngStyle]="{'background-color': comment.autor ? comment.autor.color : '#ccc'}">
        {{ comment.autor ? comment.autor.iniciales : 'UD' }}
      </div>
      <div class="comment-content">
        <strong>{{ comment.autor ? comment.autor.nombreCompleto : 'Desconocido' }}</strong>
        <p>{{ comment.contenido }}</p>
        <small>{{ comment.fechaCreacion | date:'short' }}</small>
        <div *ngIf="comment.adjuntos && comment.adjuntos.length > 0" class="adjuntos">
          <p><strong>Imágenes adjuntas del comentario:</strong></p>
          <div class="adjuntos-list">
            <div *ngFor="let adjunto of comment.adjuntos" class="adjunto-item">
              <a [href]="adjunto" target="_blank">
                <img [src]="adjunto" alt="Imagen adjunta" class="adjunto-imagen" />
              </a>
            </div>
          </div>
        </div>
        <!-- Mostrar/Ocultar respuestas -->
        <button (click)="toggleRespuestas(comment,$event)" class="ver-respuestas-btn">
          {{ comment.mostrarRespuestas ? 'Ocultar respuestas' : 'Ver respuestas a este comentario' }}
        </button>


        <!-- Respuestas a este comentario -->
        <div *ngIf="comment.mostrarRespuestas" class="respuestas" style="margin-left: 20px;">
          <div *ngFor="let respuesta of comment.respuestas" class="respuesta">
            <div class="author-avatar"
              [ngStyle]="{'background-color': respuesta.autor ? respuesta.autor.color : '#ccc'}"
              style="margin-left: 20px;">
              {{ respuesta.autor ? respuesta.autor.iniciales : 'UD' }}
            </div>
            <div class="respuesta-content">
              <strong>{{ respuesta.autor ? respuesta.autor.nombreCompleto : 'Desconocido' }}</strong>
              <p>{{ respuesta.contenido }}</p>
              <small>{{ respuesta.fechaCreacion | date:'short' }}</small>
            </div>
            <div *ngIf="respuesta.adjuntos && respuesta.adjuntos.length > 0" class="adjuntos">
              <p><strong>Imágenes adjuntas:</strong></p>
              <div class="adjuntos-list">
                <div *ngFor="let adjunto of respuesta.adjuntos" class="adjunto-item">
                  <a [href]="adjunto.url" target="_blank">
                    <img [src]="adjunto.url" alt="Imagen adjunta" class="adjunto-imagen" />
                  </a>
                </div>
              </div>
            </div>
          </div>


          <!-- Textbox para agregar nueva respuesta -->
          <div class="new-response" style="margin-left: 20px;">
            <textarea [(ngModel)]="newResponse[comment.id]" name="response-{{comment.id}}"   rows="2" placeholder="Escribe tu respuesta..."></textarea>
            <!-- <input type="file" (change)="onFileSelected2($event)" multiple class="form-control mt-2" /> -->
            <button (click)="addNewResponse(comment)" class="submit-response-btn">Responder</button>

          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="newComment">Añadir un comentario</label>
      <textarea id="newComment" [(ngModel)]="newComment" name="newComment" rows="3" class="form-control"></textarea>
      <input type="file" (change)="onFileSelected($event)" multiple class="form-control mt-2" />

      <div class="image-previews" *ngIf="imagePreviews.length > 0">
        <h5>Vista previa de las imágenes</h5>
        <div class="preview-container">
          <div class="preview" *ngFor="let preview of imagePreviews">
            <img [src]="preview" alt="Vista previa" class="thumbnail" />
          </div>
        </div>
      </div>
      
      <button (click)="addNewComment()" class="btn btn-primary mt-2">Añadir</button>
    </div>
    <!-- Botón de guardar -->
    <!-- <button type="submit" class="submit-button">Guardar Cambios</button> -->
  </form>
</div>

<!-- <div> -->
  <!-- <h1>Notificaciones</h1> -->

  <!-- Formulario para agregar notificaciones -->
  <!-- <div> -->
    <!-- <input [(ngModel)]="newMessage" placeholder="Mensaje de notificación" /> -->
    <!-- <input [(ngModel)]="ticketId" placeholder="ID del ticket" />
    <button (click)="addNotification()">Agregar Notificación</button>
  </div> -->

  <!-- Lista de notificaciones -->
  <!-- <ul>
    <li *ngFor="let notification of notifications">
      <p>{{ notification.message }}</p>
      <button (click)="markAsRead(notification.id)">Marcar como Leída</button>
      <button (click)="deleteNotification(notification.id)">Eliminar</button>
    </li>
  </ul>
</div> -->

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="closeModal()">Cerrar</button>
  <button type="submit" class="btn btn-primary" (click)="saveChangesButton()">Guardar Cambios</button>
</div>