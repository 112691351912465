<div class="page-main-header" [class.open]="open">
    <div class="main-header-right row">
      <div class="main-header-left d-lg-none col">
        <div class="logo-wrapper">
          <a [routerLink]="'/coupons/create-coupons'">
            <!-- <img class="blur-up lazyloaded" src="../../../../assets/images/dashboard/logo.svg" alt=""> -->
          </a>
        </div>
      </div>
      <div class="mobile-sidebar col">
        <div class="media-body switch-sm">
          <label class="switch">
            <a>
              <app-feather-icons id="sidebar-toggle" [icon]="'align-left'" (click)="collapseSidebar()">
              </app-feather-icons>
            </a>
          </label>
        </div>
      </div>
      <div class="nav-right col">
        <ul class="nav-menus" [ngClass]="{'open' : openNav}">
          <li class="onhover-dropdown">
            <span class="badge badge-pill badge-primary pull-right notification-badge">
              {{ unreadNotifications.length }}
            </span>
            <app-feather-icons [icon]="'bell'"></app-feather-icons>
            <span class="dot"></span>
            <ul class="notification-dropdown onhover-show-div">
              <li>
                Notificaciones
                <span class="badge badge-pill badge-primary pull-right">
                  {{ unreadNotifications.length }}
                </span>
              </li>
              <li *ngFor="let notification of unreadNotifications" (click)="openTaskDetails(notification.ticketId,notification.id)">
                <div class="media">
                  <div class="media-body">
                    <h6 class="mt-0">{{ notification.message }}</h6>
                    <p class="mb-0">{{ notification.timestamp | date: 'short' }}</p>
                    
                    <button class="btn btn-sm btn-danger" (click)="deleteNotification(notification.id); $event.stopPropagation()">
                      Eliminar
                    </button>
                  </div>
                </div>
              </li>
              <li *ngIf="unreadNotifications.length === 0">
                <p class="text-center">No hay nuevas notificaciones</p>
              </li>
            </ul>
          </li>
  
          <!-- El badge de logout sigue aquí -->
          <li class="onhover-dropdown">
            <div class="media align-items-center">
              <img class="align-self-center pull-right img-50 rounded-circle" src="assets/images/dashboard/man.png" alt="header-user">
              <div class="dotted-animation"><span class="animate-circle"></span><span class="main-circle"></span></div>
            </div>
            <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
              <li>
                <a [routerLink]="'/auth/login'" (click)="logout()">
                  <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
          <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
        </div>
      </div>
    </div>
  </div>
  