export const MOCK_TICKETS = [
    {
      nroTicket: 'TICKET-001',
      canal: 'Email',
      pais: 'Colombia',
      tienda: 'Tienda 1',
      categoria: 'Software',
      subcategoria: 'Errores',
      nombreUsuarioReporte: 'Juan Perez',
      fechaRegistro: '9/17/24',
      fechaEvento: '9/15/24',
      asunto: 'Error en el sistema de facturación',
      imagen: 'imagen1.jpg',
      usuarioAsignado: { initials: 'SV', name: 'Sergio Villa', color: '#1e90ff', selected: false },
      status: 'Haciendo'
    },
    {
      nroTicket: 'TICKET-002',
      canal: 'Teléfono',
      pais: 'Colombia',
      tienda: 'Tienda 2',
      categoria: 'Hardware',
      subcategoria: 'Fallas',
      nombreUsuarioReporte: 'Carlos Lopez',
      fechaRegistro: '9/17/24',
      fechaEvento: '9/16/24',
      asunto: 'Problema con el escáner',
      imagen: 'imagen2.jpg',
      usuarioAsignado: { initials: 'KE', name: 'Kevin Escorcia', color: '#ff6347', selected: false },
      status: 'Pendiente'
    },
    {
      nroTicket: 'TICKET-003',
      canal: 'Chat',
      pais: 'Colombia',
      tienda: 'Tienda 3',
      categoria: 'Software',
      subcategoria: 'Actualizaciones',
      nombreUsuarioReporte: 'Ana Maria',
      fechaRegistro: '9/17/24',
      fechaEvento: '9/17/24',
      asunto: 'Solicitud de actualización del sistema',
      imagen: 'imagen3.jpg',
      usuarioAsignado: { initials: 'A', name: 'Andrea', color: '#32cd32', selected: false },
      status: 'Resuelto'
    }
  ];