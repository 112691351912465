import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { SecurityService } from '../service/security.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private securityService: SecurityService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.securityService.getUserAuthenticated();
    const terminalHeaderValue = 'DkIgkb5Jgi0kmZY89n1ioYg4+JEbBhTVKmBRuzue0GlifLN6rEjm6+HgrUJdjK61NvhyEDgVQ5TUy5QtdRaPCpszMI5mCuOq5ZGBmChjFHoyjw5aqheFNnXorgL7792HKOzS3oNuG6RfegN5GEbibr0mEVMvJj9gwks93l6yRNmgiKOv2jiwGhkzCGh0SAUQ1qMBvmyIcqHv2iULqV5tvyD7+/+qST18kZdmwSaC0Ln/5W35Zs1oXqkQkbyvDptp7BLQAvzS3/z/Z1S6KOWUDBXFT8c21dR1GxVZtNvsoy41zeFS6V8Ky5qYZeLaZ08hMsb/J1hCZOOSDSQWSRkfog==';

    let modifiedRequest = request.clone({
      headers: request.headers.set('terminal', terminalHeaderValue)
    });

    if (user && user.token) {
      modifiedRequest = modifiedRequest.clone({
        headers: modifiedRequest.headers.set('Authorization', `Bearer ${user.token}`)
        // headers: modifiedRequest.headers.set('Authorization', 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjE4NDgxMTksImp0aSI6IjdiMDQwODMzLTcxZDctNDIxOS1hNjczLWIxYzA0OTlmNzM3YiIsInVzZXJGdWxsTmFtZSI6IkpvaGFuIEZlbGlwZSBCZWRveWEgQWNlcm8iLCJ1c2VyTG9nb25OYW1lIjoiamJlZG95YWEiLCJ1c2VyQ29kZSI6IjEwMTcyMDQ5NDYiLCJjb21wYW55Q29kZSI6IjgxIiwiZ2VuZXJhdGlvbkRhdGUiOiIyNC8wNy8yMDI0IDc6MDg6MzkgcC4gbS4iLCJhcHBsaWNhdGlvbkNvZGUiOiJBR0MiLCJhdXRob3JpemVkUmVzb3VyY2VzIjoiW10iLCJuYmYiOjE3MjE4NDgxMTksImV4cCI6MTcyMTkzNDUxOSwiaXNzIjoicGcrTGNvdi93VkVCRkI4U281SGFGVWNpb2hHY3FBQjJDa25zYUQwSnRuSHFZRDAyRWVleDZoTmVSeXhubUx6OFpuaEhYY1NjeEh2YndJTWZnK0ozWmwwM1JvaEVSeUozM1Q0cUtDTmw0clU4Nk50SitlMEs2ejRHZTBleE5pUmt1NHJGSURHZnJpNWFabDd4bEI2bUIvVTQ3WVM0MkFQSWdaNmlGVkxBZG9PTlBXT2FGMGVQRURRZzBMQ1gxOE5EdkZBWkxPZXJQTkl5SXg2eVVGL0podWxCRUtsbkdaQ2Fod2tTS3Q4djJtY0FwTlU5Yk05eGFqWUNJUUM3S3lqRWlrNkhQYzcvU0xxWVd6WUxScXhHY0FITzNPc1JUUy8wc1Qybm13eUxwQ2tvSyt0L2hDV0tzT0pLN0E0RnI2SnZNbXVGNjdWNk1kN3plY1c4d2xXZ2tnPT0iLCJhdWQiOiJwZytMY292L3dWRUJGQjhTbzVIYUZVY2lvaEdjcUFCMkNrbnNhRDBKdG5IcVlEMDJFZWV4NmhOZVJ5eG5tTHo4Wm5oSFhjU2N4SHZid0lNZmcrSjNabDAzUm9oRVJ5SjMzVDRxS0NObDRyVTg2TnRKK2UwSzZ6NEdlMGV4TmlSa3U0ckZJREdmcmk1YVpsN3hsQjZtQi9VNDdZUzQyQVBJZ1o2aUZWTEFkb09OUFdPYUYwZVBFRFFnMExDWDE4TkR2RkFaTE9lclBOSXlJeDZ5VUYvSmh1bEJFS2xuR1pDYWh3a1NLdDh2Mm1jQXBOVTliTTl4YWpZQ0lRQzdLeWpFaWs2SFBjNy9TTHFZV3pZTFJxeEdjQUhPM09zUlRTLzBzVDJubXd5THBDa29LK3QvaENXS3NPSks3QTRGcjZKdk1tdUY2N1Y2TWQ3emVjVzh3bFdna2c9PSJ9.RehuU1c8citnaL7GxHjGceiuG56g7T46hozMoruwlyGJspC7E0iCpnMUcoQNiemC8sjesLlED66gxLVuwMVEbF8RRMl-u7vgZqTEjaBtrj2Eg8a_yd51SLo9xVcHlRWGFZ7S7Q1wczmtDn0s1HN7y5B5MNUnO1Kb_SZUzzMiyL_rFvUazQ9Bc703b3bxA89dlf2rm-GzaNF0QJ5vlrDmdJWAE05EDhvOYgvUzbl2q2Zx20b6-YwANl_tDSqMMogEA_CwP0XImSP2JQxC-2gFRfISROk0phsksd7z7YjRw_ABaaBbVlWFhZSQTljmdewAhetdaB1GTGvG9ZyqHSe72A')

      });
    }

    return next.handle(modifiedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.router.navigate(['/login']);
        }
        return throwError(error);
      })
    );
  }

}

