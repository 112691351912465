import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	// Observable menu items
	items = new BehaviorSubject<Menu[]>([]);
	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
		   // Load menu items from localStorage
		   this.loadMenuFromStorage();
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}
	private loadMenuFromStorage() {
		const menuData = localStorage.getItem('msauc_user');
		if (menuData) {
		  const parsedMenu = JSON.parse(menuData);
		  const transformedMenu = parsedMenu.menuOptions.map((item: any) => this.transformMenuItem(item));
		  this.items.next(transformedMenu); // Update BehaviorSubject with transformed menu
		}
	  }
	
	  private transformMenuItem(item: any): Menu {
		const menuItem: Menu = {
		  path: this.extractPath(item.object),
		  title: item.name,
		  icon: this.extractIcon(item.object),
		  type: this.extractType(item.object),
		  badgeType: this.extractBadgeType(item.object),
		  active: item.active,
		  children: item.submenu?.map((subItem: any) => this.transformMenuItem(subItem)) || []
		};
		return menuItem;
	  }
	
	  private extractPath(objectString: string): string {
		return this.extractProperty(objectString, 'path');
	  }
	
	  private extractIcon(objectString: string): string {
		return this.extractProperty(objectString, 'icon');
	  }
	
	  private extractType(objectString: string): string {
		return this.extractProperty(objectString, 'type');
	  }
	
	  private extractBadgeType(objectString: string): string {
		return this.extractProperty(objectString, 'badgeType');
	  }
	
	  private extractProperty(objectString: string, property: string): string {
		const regex = new RegExp(`${property}:\\s*'([^']*)'`);
		const match = objectString.match(regex);
		return match ? match[1] : '';
	  }


		}
