import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { SecurityService } from 'src/app/shared/service/security.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { catchError } from 'rxjs/operators'; // Asegúrate de importar catchError
import { throwError } from 'rxjs'; // Asegúrate de importar throwError

@Component({
  selector: 'app-create-coupon',
  templateUrl: './create-coupon.component.html',
  styleUrls: ['./create-coupon.component.scss']
})
export class CreateCouponComponent implements OnInit {
  
  bonosForm: FormGroup;
  bonosList: any[] = [];
  user: any;
  bonosFromApi: any;
  valorDenominacionInicial: number;
  valorDenominacionFinal: number;
  nitEncriptado: any;
  apiBonosData: any[] = [];


  constructor(private fb: FormBuilder, private securityService: SecurityService, private http: HttpClient) {
    this.bonosForm = this.fb.group({
      valorBonos: ['', [Validators.required, this.currencyValidator.bind(this)]],
      cantidadBonos: ['', [Validators.required]],
      correo: ['', [Validators.required, Validators.email]],
      observacion: ['']
    });
  }

  ngOnInit() {
    this.loadBonosFromLocalStorage();
    this.user = this.securityService.getUserAuthenticated();
    this.get(); 
  }

  validateQuantity(event: any) {
    let input = event.target.value.replace(/\D/g, '');
    if (input.length > 4) {
      input = input.slice(0, 4);
    }
    event.target.value = input;
    this.bonosForm.controls['cantidadBonos'].setValue(input, { emitEvent: false });
  }

  formatCurrency(event: any) {
    let input = event.target.value.replace(/[\D\s\._\-]+/g, "");
    input = input ? parseInt(input, 10) : 0;

    let formattedInput = input.toLocaleString("es-ES");
    event.target.value = `$${formattedInput}`;
    this.bonosForm.controls['valorBonos'].setValue(event.target.value, { emitEvent: false });
    this.bonosForm.controls['valorBonos'].updateValueAndValidity(); // Trigger validation
  }

  deleteBono(index: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminarán los bonos permanentemente',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.bonosList.splice(index, 1);
        this.saveBonosToLocalStorage();
      }
    });
  }
  addBono() {
    const valorBonosControl = this.bonosForm.get('valorBonos');
    const cantidadBonosControl = this.bonosForm.get('cantidadBonos');
    const correoControl = this.bonosForm.get('correo');

    const valorBonosValue = valorBonosControl?.value?.trim() || '';
    const cantidadBonosValue = cantidadBonosControl?.value?.trim() || '';
    const correoValue = correoControl?.value?.trim() || '';

    if (this.bonosForm.valid && valorBonosValue && cantidadBonosValue && correoValue) {
      this.bonosList.push(this.bonosForm.value);
      this.saveBonosToLocalStorage();
      this.resetForm();
      Swal.fire('Bono agregado con éxito', '', 'success');
    } else {
      let errorMessage = 'Error al agregar bono. ';
      if (!valorBonosValue) {
        errorMessage += 'El valor del bono es obligatorio. ';
      }
      if (!cantidadBonosValue) {
        errorMessage += 'La cantidad de bonos es obligatoria. ';
      }
      if (!correoValue) {
        errorMessage += 'El correo es obligatorio. ';
      }
      Swal.fire('Error', errorMessage.trim(), 'error');
    }
  }

  currencyValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.replace(/[\D\s\._\-]+/g, "");
    const amount = value ? parseInt(value, 10) : NaN;
    
    if (isNaN(amount) || amount <= 0) {
      return { invalidCurrency: true };
    }

    if (amount < this.valorDenominacionInicial || amount > this.valorDenominacionFinal) {
      return { currencyRange: true };
    }

    return null;
  }


 
  resetForm() {
    this.bonosForm.reset();
  }

  saveBonosToLocalStorage() {
    localStorage.setItem('bonosList', JSON.stringify(this.bonosList));
  }

  loadBonosFromLocalStorage() {
    const storedBonos = localStorage.getItem('bonosList');
    if (storedBonos) {
      this.bonosList = JSON.parse(storedBonos);
    }
  }



  enviarCorreoConBonos(bono: any, data: any) {
    console.log('bono api enviar', bono);
    const correo = bono.correo;
    const senderEmail = "retail@leonisa.com";
    const senderName = " Bonos digitales Leonisa";
    const subject = " Bonos digitales Leonisa";
    const message = this.construirMensajeCorreo(data, bono); // Ajustado para usar la respuesta de la API

    const payload = {
        senderEmail,
        senderName,
        recipients: [correo],
        subject,
        message
    };

    return this.http.post('https://srvextranet.leonisa.com/Aplicativos_Informaticos/RetailSuiteService/api/ltp/enviarCorreo', payload)
        .pipe(
            catchError(error => {
                console.error('Error al enviar el correo:', error);
                Swal.fire('Error', 'Hubo un problema al enviar el correo', 'error');
                return throwError(error);
            })
        )
        .toPromise()
        .then(response => {
            Swal.fire('Éxito', 'Correo enviado exitosamente', 'success');
        });
}

construirMensajeCorreo(bonos: any[], data: any ): string {
  console.log('html', bonos);
  const logoUrl = 'https://firebasestorage.googleapis.com/v0/b/incentivosvotre.appspot.com/o/BonosDigitales%2Flogo2.png?alt=media&token=4ff9c325-b154-46f3-9b27-695f15070b9b';

  let mensaje = `
    <div style="text-align: center; margin-bottom: 20px;">
      <img src="${logoUrl}" alt="logo" width="400">
    </div>
    <p style="color: #000000;">Se han generado los siguientes bonos:</p>
    <table style="width: 100%; border-collapse: collapse; margin: 20px 0;">
      <tr style="background-color: rgb(89, 79, 160); color: white;">
        <th style="border: 1px solid #ddd; padding: 8px;">Código</th>
        <th style="border: 1px solid #ddd; padding: 8px;">Saldo</th>
        <th style="border: 1px solid #ddd; padding: 8px;">Fecha Inicio Vigencia</th>
        <th style="border: 1px solid #ddd; padding: 8px;">Fecha Fin Vigencia</th>
        <th style="border: 1px solid #ddd; padding: 8px;">Observación</th>

      </tr>`;
  
  bonos.forEach(bono => {
    mensaje += `
      <tr>
        <td style="border: 1px solid #ddd; padding: 8px;">${bono.codigo}</td>
        <td style="border: 1px solid #ddd; padding: 8px;">$${bono.saldo}</td>
        <td style="border: 1px solid #ddd; padding: 8px;">${this.formatearFecha(bono.fechaInicioVigencia)}</td>
        <td style="border: 1px solid #ddd; padding: 8px;">${this.formatearFecha(bono.fechaFinVigencia)}</td>
        <td style="border: 1px solid #ddd; padding: 8px;">${data.observacion}</td>

      </tr>`;
  });
  
  mensaje += `
   </table>
  <p style="color: #000000;">
    Bono de compra redimible al portador por su valor cargado, total o parcialmente, en tiendas Leonisa, Outlets, tiendas Zebra, Lumar by Leonisa y línea única de whatsapp en Colombia. No es redimible en la página web <a href="http://www.leonisa.com/col" style="color: rgb(89, 79, 160);">http://www.leonisa.com/col</a>. No es canjeable por dinero en efectivo.
    Vigencia: un (1) año contado a partir de la fecha de su emisión, vencido el cual se perderá el valor remanente. Para redimir el bono es indispensable indicar el código correspondiente al momento de la compra.
  </p>
`;
  
  return mensaje;
}


printLocalStorage() {
  Swal.fire({
    title: '¿Está seguro?',
    text: "¿Desea generar los bonos agregados?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sí, enviar',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      const storedBonos = localStorage.getItem('bonosList');
      const user = this.securityService.getUserAuthenticated();
      this.apiBonosData = [];


      this.DataEncriptada().then(encriptado => {
        this.nitEncriptado = encriptado;

        // if (storedBonos) {
        //   const bonosList = JSON.parse(storedBonos);
        //   const promises = bonosList.map((bono: any) => {
        //     const jsonPayload = {
        //       observaciones: bono.observacion,
        //       nit: user.nitEmpresa,
        //       bonos: [{
        //         denominacion: bono.valorBonos.replace(/\$/g, '').replace(/\./g, '').trim(),
        //         cantidad: bono.cantidadBonos
        //       }]
        //     };

        //     const headers = {
        //       'nit': this.nitEncriptado
        //     };

        //     return this.securityService.addBonos(jsonPayload, headers).pipe(
        //       catchError(error => {
        //         console.error('Error al registrar bono:', error);
        //         Swal.fire('Error', 'Hubo un problema al registrar el bono', 'error');
        //         return throwError(error);
        //       })
        //     ).toPromise()
        //     .then((response: any) => {
        //       console.log('response', response);

        //       // Verificar que response.bonos sea un array antes de usarlo
        //       if (response && Array.isArray(response.bonos)) {
        //         this.apiBonosData.push(...response.bonos); // Acumular los datos
        //         return this.enviarCorreoConBonos(bono, response.bonos);
        //       } else {
        //         console.error('Estructura de respuesta inesperada:', response);
        //         Swal.fire('Error', 'Respuesta de la API no válida', 'error');
        //       }
        //     });
        //   });

        //   Promise.all(promises).then(() => {
        //     // Descargar un único archivo Excel con todos los datos
        //     this.downloadExcel(this.apiBonosData);

        //     // Limpiar el localStorage y la lista de bonos
        //     localStorage.removeItem('bonosList'); 
        //     this.bonosList = []; 
        //     Swal.fire('Éxito', 'Bonos registrados exitosamente', 'success');
        //   }).catch(error => {
        //     console.error('Error al procesar bonos:', error);
        //   });
        // } else {
        //   console.log('No hay datos en el local storage');
        // }
      }).catch(error => {
        console.error('Error encriptando data:', error);
      });
    }
  });
}

prepararDatosParaExcel(bonos: any[]) {
  return bonos.map(bono => ({
    'Código': bono.codigo || 'N/A',
    'Saldo': bono.saldo || 'N/A',
    'Fecha Inicio Vigencia': this.formatearFecha(bono.fechaInicioVigencia) || 'N/A',
    'Fecha Fin Vigencia': this.formatearFecha(bono.fechaFinVigencia) || 'N/A',
  }));
}

downloadExcel(data: any[]) {
  console.log('data excel', data);
  const datosParaExcel = this.prepararDatosParaExcel(data);
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datosParaExcel);
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Bonos');
  XLSX.writeFile(workbook, `Bonos_${new Date().getTime()}.xlsx`);
}
  formatearFecha(fechaIso: string): string {
    if (!fechaIso) {
        return 'N/A';
    }
    const opciones: Intl.DateTimeFormatOptions = {
        year: 'numeric', month: '2-digit', day: '2-digit',
    };
    const fecha = new Date(fechaIso);
    if (isNaN(fecha.getTime())) {
        return 'N/A';
    }
    const fechaFormateada = new Intl.DateTimeFormat('es-ES', opciones).format(fecha);
    return fechaFormateada.replace(',', '').replace(/:\d{2}$/, '');
}
  
  DataEncriptada(): Promise<string> {
    return new Promise((resolve, reject) => {
      const user = this.securityService.getUserAuthenticated();
      const data = {
        "Texto": user.nitEmpresa
      };
      // this.securityService.encriptacionData(data).subscribe(
      //   (response: any) => {
      //     console.log('response encriptada', response.texto);
      //     resolve(response.texto);
      //   },
      //   (error) => {
      //     console.error('Error fetching data:', error);
      //     reject(error);
      //   }
      // );
    });
  }
  get() {
    const user = this.securityService.getUserAuthenticated();
    console.log('user abajo', user);
  
    const data = {
      "accion": "L"
    }
  
    // this.securityService.getGiftCard(data).subscribe(
    //   (response: any) => {
    //     console.log('response', response);
    //     const parsedResponse = JSON.parse(response.result); 
    //     this.bonosFromApi = parsedResponse.bonos; 
    //     this.valorDenominacionInicial = parsedResponse.valorDenominacionInicial; 
    //     this.valorDenominacionFinal = parsedResponse.valorDenominacionFinal; 
    //     console.log("inicial", this.valorDenominacionInicial)
    //     console.log("final", this.valorDenominacionFinal)
    //   },
    //   (error) => {
    //     console.error('Error fetching data:', error);
    //   }
    // );
  }

  
  

}