<div class="login-page">
    <div class="container-fluid d-flex justify-content-center align-items-center">
        <div class="row w-100 h-100">
            <div class="col-12 col-md-7 d-none d-md-block p-0 h-100">
                <div class="left-side" style="background-image: url('assets/images/banner-login.jpg');"></div>
            </div>
            <div class="col-12 col-md-5 p-0 h-100">
                <div class="right-side d-flex flex-column justify-content-center align-items-center">
                    <div class="login-form-container">
                        <div class="text-center mb-4">
                            <img src="assets/images/icon/image.webp" alt="logo" width="400">
                        </div>
                        <div class="theme-card p-4">
                            <form [formGroup]="loginForm">
                                
                                <div class="form-group mt-4">
                                    <label for="email"><strong>Ingresa el Usuario</strong></label>
                                    <input type="text" class="form-control" id="email" placeholder="Correo electrónico" formControlName="email" required>
                                    <div *ngIf="loginForm.get('email').invalid && loginForm.get('email').touched">
                                        <small class="text-danger" *ngIf="loginForm.get('email').errors?.required">
                                            El usuario es requerido.
                                        </small>
                                        <small class="text-danger" *ngIf="loginForm.get('email').errors?.email">
                                            Ingresa un usuario valido.
                                        </small>
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="password"><strong>Contraseña</strong></label>
                                    <input type="password" class="form-control" id="password" placeholder="Ingresa tu contraseña" formControlName="pwd" required>
                                    <div *ngIf="loginForm.get('pwd').invalid && loginForm.get('pwd').touched">
                                     
                                       
                                    </div>
                                </div>
                                <div class="button-container">
                                    <button class="btn btn-solid mt-4 mr-2 custom-btn" type="button" (click)="onLogin()">Ingresar</button>
                                </div>
                               
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
