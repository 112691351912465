import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notifications$ = new BehaviorSubject<any[]>([]);
  private userId = 'usuarioId1'; // Cambiar por el usuario autenticado

  constructor(private db: AngularFireDatabase,private toastr: ToastrService) {
    this.listenForNotifications();
  }

  // Escucha notificaciones en tiempo real
  private listenForNotifications() {
    this.db.list('notificaciones').snapshotChanges().subscribe((snapshots) => {
      const notifications = snapshots.map((snapshot) => {
        const data: any = snapshot.payload.val();
        const id = snapshot.key; // Obtener el key (nombre del nodo)
        return { id,show: false, ...data }; // Combinar el key con los datos
      });
  
      const sorted = notifications.sort((a, b) => b.timestamp - a.timestamp); // Ordenar por más recientes
      this.notifications$.next(sorted);
      const newNotifications = sorted.filter((n) => !n.read);
      if (newNotifications.length > 0) {
        newNotifications.forEach((notification) => {
          this.toastr.info(notification.message, 'Tienes nuevas notificaciones', {
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
          });
        });
      }
    });
  }
  

  // Obtener las notificaciones como observable
  getNotifications() {
    return this.notifications$.asObservable();
  }

  // Marcar una notificación como leída
  markAsRead(notificationId: string): Promise<void> {
    return this.db.object(`notificaciones/${notificationId}`).update({ read: true });
  }

 // Eliminar una notificación
 deleteNotification(notificationId: string): Promise<void> {
  return this.db.object(`notificaciones/${notificationId}`).remove();
}

   // Agregar una nueva notificación
   addNotification(message: string, ticketId: string) {
    const ref = this.db.list('notificaciones');
    return ref.push({
      message,
      ticketId,
      timestamp: Date.now(),
      read: false
    });
  }
  addNotificationKt(message: string, ticketId: string) {
    const ref = this.db.list('ActualizacionTicket');
    return ref.push({
      message,
      ticketId,
      timestamp: Date.now(),
      read: false
    });
  }

  // Eliminar notificaciones caducadas (llamar periódicamente)
  deleteExpiredNotifications(expirationTime: number) {
    const ref = this.db.list('notificaciones');
    ref.snapshotChanges().subscribe((snapshots) => {
      snapshots.forEach((snapshot) => {
        const key = snapshot.key;
        const data: any = snapshot.payload.val();
        if (data.timestamp + expirationTime < Date.now()) {
          this.deleteNotification(key!);
        }
      });
    });
  }
}
