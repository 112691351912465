import { Routes } from '@angular/router';


export const content: Routes = [
 


  {
    path: 'tickets',
    loadChildren: () => import('../../components/backlog-tickets/backlog-tickets.module').then(m => m.BacklogTicketsModule),
    data: {
      breadcrumb: "Backlog-Tickets"
    }
  },
  {
    path: 'Maestros',
    loadChildren: () => import('../../components/Maestros/maestro-tickets.module').then(m => m.MaestrosTicketsModule),
    data: {
      breadcrumb: "Maestros"
    }
  }
 

 
 
  
 



  
];