import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { StorageService } from '../../service/storage.service';
import { TicketService } from '../../service/ticket.service';  // Importa tu servicio de tickets

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;
  public assignedTicketsCount: number = 0;  // Contador de tickets asignados
  public currentUserEmail: string = '';  // Correo electrónico del usuario actual

  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  newTickets: any=0;

  constructor(
    public navServices: NavService,
    private storageService: StorageService,
    private ticketService: TicketService  // Inyecta el servicio de tickets
  ) { }

  // Método para obtener el correo electrónico del usuario actual
  getCurrentUserEmail() {
    // Asegúrate de que tienes una manera de obtener el correo del usuario actual desde el almacenamiento o una API
    const user = this.storageService.getItem('msauc_user');  // Asume que tienes el usuario guardado en el almacenamiento
    if (user && user.personalMail) {
      this.currentUserEmail = JSON.parse(localStorage.getItem('msauc_user')).companyMail;
    }
  }

  // Método para cargar los tickets y comparar
  loadTickets() {
    
    this.ticketService.getTickets().subscribe((tickets: any) => {
      this.ticketService.getUsers().subscribe((users: any) => {
        // Encuentra al usuario actual basado en su correo electrónico
        const currentUser = users.result.find(user => user.email === this.currentUserEmail);
        if (currentUser) {
          // Filtrar los tickets asignados a este usuario
          const assignedTickets = tickets.result.filter(ticket => ticket.usuarioMesaAyuda.email === currentUser.email);
          this.assignedTicketsCount = assignedTickets.length;  // Asignar el conteo de tickets
        }
      });
    });
    this.ticketService.getTicketByUser({'email':JSON.parse(localStorage.getItem('msauc_user')).companyMail}).subscribe((tickets1: any) => {
      console.log(tickets1)
      this.newTickets=tickets1.result
    })
  }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  logout() {
    this.storageService.removeItem('msauc_user');
  }

  ngOnInit() {
    this.getCurrentUserEmail();  // Cargar el correo del usuario actual
    setInterval(() => this.loadTickets(), 10000);  // Cargar los tickets

  }
}
