import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SecurityService } from 'src/app/shared/service/security.service';
import * as XLSX from 'xlsx';
import { format, subMonths } from 'date-fns';


@Component({
  selector: 'app-list-coupon',
  templateUrl: './list-coupon.component.html',
  styleUrls: ['./list-coupon.component.scss'],
})
export class ListCouponComponent  {
  fechaForm: FormGroup;
  botonHabilitado: boolean = false;
  datosParaExportar: any[] = [];
  cargando: boolean = false;
  nitEncriptado: string;
  cargas: any[] = [];
  nombreComercio: string;

  constructor(private fb: FormBuilder, private securityService: SecurityService) {
    const fechaFinal = new Date();
    const fechaInicio = subMonths(fechaFinal, 1);

    this.fechaForm = this.fb.group({
      fechaInicio: [format(fechaInicio, 'yyyy-MM-dd')],
      fechaFin: [format(fechaFinal, 'yyyy-MM-dd')]
    });
  }

  ngOnInit(): void {
    this.enviarFechas();
  }

  resetForm() {
    this.fechaForm.reset();
    this.botonHabilitado = false;
  }

  enviarFechas() {
    const user = this.securityService.getUserAuthenticated();
    this.DataEncriptada().then(encriptado => {
      this.nitEncriptado = encriptado;
  
      const headers = { 'nit': this.nitEncriptado };
      const valores = this.fechaForm.value;
  
      // Asegurarse de ajustar la fecha para evitar cambios inesperados
      const fechaInicial = this.formatearFechaParaEnvio(valores.fechaInicio);
      const fechaFinal = this.formatearFechaParaEnvio(valores.fechaFin);
  
      const data = {
        "nit": user.nitEmpresa,
        "fechaInicial": fechaInicial,
        "fechaFinal": fechaFinal
      };
  
      // this.securityService.getBonos(data, headers).subscribe({
      //   next: (response) => {
      //     if (response.cargas && response.cargas.length > 0) {
      //       this.cargas = response.cargas;
      //       this.nombreComercio = response.nombreComercio; 
      //     } else {
      //       this.cargas = [];
      //       console.warn('No existen registros para los datos ingresados.');
      //     }
      //   },
      //   error: (error) => {
      //     console.error('Error en la llamada a la API:', error);
      //     this.botonHabilitado = false;
      //     this.cargando = false;
      //   }
      // });
    }).catch(error => {
      console.error('Error encriptando data:', error);
    });
  }

  formatearFechaParaEnvio(fecha: string): string {
    const partes = fecha.split('-');
    const fechaAjustada = new Date(
      Number(partes[0]), 
      Number(partes[1]) - 1, 
      Number(partes[2])
    );
    
    return format(fechaAjustada, 'yyyyMMdd');
  }

  DataEncriptada(): Promise<string> {
    return new Promise((resolve, reject) => {
      const user = this.securityService.getUserAuthenticated();
      const data = { "Texto": user.nitEmpresa };
      // this.securityService.encriptacionData(data).subscribe(
      //   (response: any) => resolve(response.texto),
      //   (error) => reject(error)
      // );
    });
  }

  exportarComoExcel() {
    const datosParaExportar = this.cargas.map(carga => {
      return carga.bonos.map(bono => ({
        'Fecha de Carga': this.formatearFecha(carga.fechaCarga),
        'Código Bono': bono.codigo,
        'Saldo': bono.saldo,
        'Valor': bono.valor,
        'Fecha Inicio Vigencia': this.formatearFecha(bono.fechaInicioVigencia),
        'Fecha Fin Vigencia': this.formatearFecha(bono.fechaFinVigencia),
        'Estado': bono.nombreEstado,
        'Nombre Comercio': this.nombreComercio
      }));
    }).flat();

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datosParaExportar);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Reporte');
    XLSX.writeFile(workbook, 'Reporte.xlsx');
  }

  formatearFecha(fechaIso: string): string {
    const opciones: Intl.DateTimeFormatOptions = {
      year: 'numeric', month: '2-digit', day: '2-digit',
    };
    const fecha = new Date(fechaIso);
    const fechaFormateada = new Intl.DateTimeFormat('es-ES', opciones).format(fecha);
    return fechaFormateada.replace(',', '').replace(/:\d{2}$/, '');
  }


 


  

}
