import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MOCK_TICKETS } from '../../mock/mock-tickets'; // Asegúrate de ajustar la ruta
 // Asegúrate de que el modelo Ticket esté definido

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  private tickets: any[] = MOCK_TICKETS;

  constructor(private http: HttpClient) {}

  getTickets(): Observable<any[]> {
    const headers = new HttpHeaders({
      'keyApp': 'e56dadf569d54b13809e94e95c97b31c',
      'Authorization': sessionStorage.getItem('tk') || ''
    });

    return this.http.get<any[]>('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/GetTickets', { headers });
  }
  updateImageComents(newAttachment: any): Observable<any>  {
    
      // Configuramos los headers con keyApp y Authorization
      const headers = new HttpHeaders({
        'keyApp': 'e56dadf569d54b13809e94e95c97b31c',  // Reemplaza 'TU_KEY_APP' por la clave correcta
        'Authorization': sessionStorage.getItem('tk')  // Reemplaza 'TU_TOKEN' por el token correcto
      });
  
      // Hacemos el POST a la API
      return this.http.post('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/UploadFileTicketComment', newAttachment, { headers });
    }
    updateImageResponse(newAttachment: any): Observable<any>  {
    
      // Configuramos los headers con keyApp y Authorization
      const headers = new HttpHeaders({
        'keyApp': 'e56dadf569d54b13809e94e95c97b31c',  // Reemplaza 'TU_KEY_APP' por la clave correcta
        'Authorization': sessionStorage.getItem('tk')  // Reemplaza 'TU_TOKEN' por el token correcto
      });
  
      // Hacemos el POST a la API
      return this.http.post('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/UploadFileReplyTicketComment', newAttachment, { headers });
    }
    getTicketByUser(user: any): Observable<any>  {
    
      // Configuramos los headers con keyApp y Authorization
      const headers = new HttpHeaders({
        'keyApp': 'e56dadf569d54b13809e94e95c97b31c',  // Reemplaza 'TU_KEY_APP' por la clave correcta
        'Authorization': sessionStorage.getItem('tk')  // Reemplaza 'TU_TOKEN' por el token correcto
      });
  
      // Hacemos el POST a la API
      return this.http.post('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/GetTicketsByUser', user, { headers });
    }
 
  editTicket(newTicket: any): Observable<any>  {
    
    // Configuramos los headers con keyApp y Authorization
    const headers = new HttpHeaders({
      'keyApp': 'e56dadf569d54b13809e94e95c97b31c',  // Reemplaza 'TU_KEY_APP' por la clave correcta
      'Authorization': sessionStorage.getItem('tk')  // Reemplaza 'TU_TOKEN' por el token correcto
    });

    return this.http.put('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/UpdateTickets', newTicket, { headers });
  }
  addTicket(newTicket: any): Observable<any>  {
    
      // Configuramos los headers con keyApp y Authorization
      const headers = new HttpHeaders({
        'keyApp': 'e56dadf569d54b13809e94e95c97b31c',  // Reemplaza 'TU_KEY_APP' por la clave correcta
        'Authorization': sessionStorage.getItem('tk')  // Reemplaza 'TU_TOKEN' por el token correcto
      });
  
      // Hacemos el POST a la API
      return this.http.post('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/AddTickets', newTicket, { headers });
    }

    addCategory(category:any): Observable<any>  {
    
      // Configuramos los headers con keyApp y Authorization
      const headers = new HttpHeaders({
        'keyApp': 'e56dadf569d54b13809e94e95c97b31c',  // Reemplaza 'TU_KEY_APP' por la clave correcta
        'Authorization': sessionStorage.getItem('tk')  // Reemplaza 'TU_TOKEN' por el token correcto
      });
  
      // Hacemos el POST a la API
      return this.http.post('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/AddCategorias', category, { headers });
    }

    getCategories(): Observable<any[]> {
      const headers = new HttpHeaders({
        'keyApp': 'e56dadf569d54b13809e94e95c97b31c',
        'Authorization': sessionStorage.getItem('tk') || ''
      });
  
      return this.http.get<any[]>('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/GetCategorias', { headers });
    }
    getUsers(): Observable<any[]> {
      const headers = new HttpHeaders({
        'keyApp': 'e56dadf569d54b13809e94e95c97b31c',
        'Authorization': sessionStorage.getItem('tk') || ''
      });
  
      return this.http.get<any[]>('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/GetPersonas', { headers });
    }
    
    addUser(category:any): Observable<any>  {
    
      // Configuramos los headers con keyApp y Authorization
      const headers = new HttpHeaders({
        'keyApp': 'e56dadf569d54b13809e94e95c97b31c',  // Reemplaza 'TU_KEY_APP' por la clave correcta
        'Authorization': sessionStorage.getItem('tk')  // Reemplaza 'TU_TOKEN' por el token correcto
      });
  
      // Hacemos el POST a la API
      return this.http.post('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/AddPersonas', category, { headers });
    }
    getChannels(): Observable<any[]> {
      const headers = new HttpHeaders({
        'keyApp': 'e56dadf569d54b13809e94e95c97b31c',
        'Authorization': sessionStorage.getItem('tk') || ''
      });
  
      return this.http.get<any[]>('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/GetCanales', { headers });
    }

    addChannel(category:any): Observable<any>  {
    
      // Configuramos los headers con keyApp y Authorization
      const headers = new HttpHeaders({
        'keyApp': 'e56dadf569d54b13809e94e95c97b31c',  // Reemplaza 'TU_KEY_APP' por la clave correcta
        'Authorization': sessionStorage.getItem('tk')  // Reemplaza 'TU_TOKEN' por el token correcto
      });
  
      // Hacemos el POST a la API
      return this.http.post('https://omnicanalidad.leonisa360.com/gestiontickets/rest/v1/AddCanales', category, { headers });
    }
    
    
}
