import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { SecurityService } from 'src/app/shared/service/security.service';
import { StorageService } from 'src/app/shared/service/storage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  secretKey = "3cR#tK3y!9oP$uR3&6Fddsgt493H-o";
  public validarClickeado: boolean = false;
  showPassword: boolean = false;
  showAuthorizationCheckbox: boolean = true;
  mostrarAutorizacion: boolean = false;

  constructor(private securityService: SecurityService, private router: Router, private storageService: StorageService) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      authorization: new FormControl(false),
      email: new FormControl('', [Validators.required]),
      pwd: new FormControl('', [
        Validators.required,
      ]),
    });

    if (!this.showAuthorizationCheckbox) {
      this.loginForm.get('authorization').setValidators(Validators.requiredTrue);
    }
  }

  passwordStrengthValidator(control: FormControl): { [key: string]: any } | null {
    const value = control.value || '';
    let error = null;
    return error;
  }

  onLogin() {
    if (this.loginForm.valid) {
      const formValue = this.loginForm.value;

      // Verificar credenciales de acceso directo para admin
      if (formValue.email === 'admin' && formValue.pwd === 'admin') {
        const fakeResponse = {
          token: 'fake-admin-token',
          user: {
            name: 'Admin',
            role: 'Administrator'
          }
        };
        sessionStorage.setItem('tk', fakeResponse.token);
        this.storageService.setItem('msauc_user', fakeResponse);
        this.router.navigate(['/tickets/backlog-tickets']);
        return;
      }

      // Continuar con el flujo normal para otros usuarios
      const loginPayload = {
        "userLogonName": formValue.email,
        "password": formValue.pwd,
        "keyApp": "GTR"
      };

      console.log(loginPayload);
      this.securityService.authenticationservice(loginPayload).subscribe({
        next: response => {
          if (response) {
            sessionStorage.setItem('tk', response.token);
            this.storageService.setItem('msauc_user', response);
            this.router.navigate(['/tickets/backlog-tickets']);
          }
        },
        error: error => {
          console.error('There was an error!', error);
          this.AlertaFallo();
        }
      });
    }
  }

  AlertaFallo() {
    Swal.fire({
      title: 'Correo Inválido',
      text: 'El correo no existe',
      icon: 'warning',
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  AlertaAutorizacion() {
    Swal.fire({
      title: 'Autorización Inválida',
      text: 'Debes validar nuevamente los campos de autorización',
      icon: 'warning',
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }
}
