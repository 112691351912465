import { Injectable } from '@angular/core';
import { CoreService } from '../core.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class SecurityService extends CoreService {

  constructor(protected override http: HttpClient, private storageService: StorageService) { 
    super(http);
}

  authenticationservice(datos: any) {
    return this.http.post<any>('https://srvappsha.leonisa.com/Aplicativos_Informaticos/authservice/api/v2/login', datos)
  }

  // addBonos(data: any, headers: any) {
  //   const httpHeaders = new HttpHeaders(headers);
  //   return this.http.post<any>('https://srvextranet.leonisa.com/Aplicativos_Informaticos/posservice/api/bonos/emitirBonosDigitalesComercios',data, { headers: httpHeaders })
  // }

  // encriptacionData(datos: any) {
  //   return this.http.post<any>('https://srvextranet.leonisa.com/Aplicativos_Informaticos/posservice/api/utilidades/encriptarTexto', datos)
  // }

  // getGiftCard(datos: any) {
  //   return this.http.post<any>('https://srvextranet.leonisa.com/Aplicativos_Informaticos/posservice/api/giftCard/getGiftCard', datos)
  // }

  // getBonos(data: any, headers: any) {
  //   const httpHeaders = new HttpHeaders(headers);
  //   return this.http.post<any>('https://srvextranet.leonisa.com/Aplicativos_Informaticos/posservice/api/bonos/listarBonosDigitalesComercios',data, { headers: httpHeaders })
  // }
  // authenticationservice(email: string, pwd: string) {
  //   return this.post<any>('/Authentication/authenticate', {email, pwd})
  // }
  changeLoggedIn(arg0: boolean) {
    throw new Error('Method not implemented.');
  }
  
  private loggedIn = new BehaviorSubject<boolean>(false);


  logout() {
    this.loggedIn.next(false);
    this.storageService.removeItem('msauc_user');
}

  get isLoggedIn() {
    return this.storageService.getItem('msauc_user') ? true : false;
}

getUserAuthenticated() {
  if (this.isLoggedIn) {
      return this.storageService.getItem('msauc_user');
  }
}
  
 

}
