import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { SecurityService } from 'src/app/shared/service/security.service';
import { StorageService } from 'src/app/shared/service/storage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  secretKey = "3cR#tK3y!9oP$uR3&6Fddsgt493H-o";
  public validarClickeado: boolean = false;
  showPassword: boolean = false;
  showAuthorizationCheckbox: boolean = true;
  mostrarAutorizacion: boolean = false;
  user: { id: string; nombreCompleto: string; iniciales: string; color: string; email: string; celular: string; fechaCreacion: string; }[];

  constructor(private securityService: SecurityService, private router: Router, private storageService: StorageService) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      authorization: new FormControl(false),
      email: new FormControl('', [Validators.required]),
      pwd: new FormControl('', [
        Validators.required,
      ]),
    });

    if (!this.showAuthorizationCheckbox) {
      this.loginForm.get('authorization').setValidators(Validators.requiredTrue);
    }
  }

  passwordStrengthValidator(control: FormControl): { [key: string]: any } | null {
    const value = control.value || '';
    let error = null;
    return error;
  }

  onLogin() {
    if (this.loginForm.valid) {
      const formValue = this.loginForm.value;

      const users = {
        admin: 'admin',
        jnavarrog: 'admin2', // Contraseña específica para jnavarrog
        dgarciah: 'admin3',
        sgarcia: 'admin4'  // Contraseña específica para dgarciah
      };
      
      if (users[formValue.email] && users[formValue.email] === formValue.pwd) {
        const fakeResponse = {
          token: 'fake-admin-token',
          user: {
            name: 'Admin',
            role: 'Administrator'
          }
        };
        if(formValue.email.toLowerCase()==="admin"){
          this.user = [{
            "id": "5",
            "nombreCompleto": "Jairo Arango",
            "iniciales": "JA",
            "color": "#e6f00b",
            "email": "jarango@almara.com",
            "celular": "32",
            "fechaCreacion": "2024-11-06T13:16:04.0316182+00:00"
          }];
        }else if (formValue.email.toLowerCase()==="jnavarrog"){
          
          this.user = [{
          "id": "6",
            "nombreCompleto": "Julian Navarro",
            "iniciales": "JN",
            "color": "#0b15f0",
            "email": "jlbatty113@gmail.com",
            "celular": "3015380656",
            "fechaCreacion": "2024-11-06T13:16:04.0316182+00:00"
        }]
        }else if(formValue.email.toLowerCase()==="dgarciah" ){
          this.user = [{
            "id": "7",
              "nombreCompleto": "Daniel Garcia",
              "iniciales": "DG",
              "color": "#abf00b",
              "email": "danielmg12361@gmail.com",
              "celular": "3243564016",
              "fechaCreacion": "2024-11-06T13:16:04.0316182+00:00"
          }]
        }else if(formValue.email.toLowerCase()==="sgarcia"){
          this.user = [{
            "id": "8",
              "nombreCompleto": "Santiago Garcia",
              "iniciales": "SG",
              "color": "#f00bae",
              "email": "sgarcia@gmail.com",
              "celular": "3024218994",
              "fechaCreacion": "2024-11-06T13:16:04.0316182+00:00"
          }]
        }
        
        sessionStorage.setItem('tk', fakeResponse.token);
        this.storageService.setItem('user',this.user[0])
        this.storageService.setItem('msauc_user', fakeResponse);
        this.router.navigate(['/tickets/backlog-tickets']);
        return;
      }
      
      // Si no hay coincidencia
      alert('Usuario o contraseña incorrectos');
      

      // Continuar con el flujo normal para otros usuarios
      const loginPayload = {
        "userLogonName": formValue.email,
        "password": formValue.pwd,
        "keyApp": "GTR"
      };

      console.log(loginPayload);
      this.securityService.authenticationservice(loginPayload).subscribe({
        next: response => {
          if (response) {
            sessionStorage.setItem('tk', response.token);
            this.storageService.setItem('msauc_user', response);
            this.router.navigate(['/tickets/backlog-tickets']);
          }
        },
        error: error => {
          console.error('There was an error!', error);
          this.AlertaFallo();
        }
      });
    }
  }

  AlertaFallo() {
    Swal.fire({
      title: 'Correo Inválido',
      text: 'El correo no existe',
      icon: 'warning',
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  AlertaAutorizacion() {
    Swal.fire({
      title: 'Autorización Inválida',
      text: 'Debes validar nuevamente los campos de autorización',
      icon: 'warning',
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  togglePasswordVisibility(): void {
    const passwordField = document.getElementById('password') as HTMLInputElement;
    if (passwordField.type === 'password') {
      passwordField.type = 'text';
    } else {
      passwordField.type = 'password';
    }
  }



}
