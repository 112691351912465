import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketService } from 'src/app/shared/service/ticket.service';
import Swal from 'sweetalert2';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/service/rtdatabase.service';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent implements OnInit{
  Swal: any;
  selectedFiles: File[] = [];
  imageBase64: any;
  imageBase642: any;
  responseComSupport: any;
  imagePreviews: any[]=[];
  newMessage = '';
  ticketId = '';
  notifications: any[]=[];
  constructor(private notificationService: NotificationService, public activeModal: NgbActiveModal,private TicketService:TicketService,private storage:AngularFireStorage){

  }
  onFileSelected(event: any) {
    const files = event.target.files;
  
    if (files && files.length > 0) {
      this.selectedFiles = Array.from(files) as File[];
      this.imagePreviews = [];
  
      for (const file of this.selectedFiles) {
        if (file instanceof Blob) { // Verifica que sea un Blob (o File)
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.imagePreviews.push(e.target.result); // Agrega la miniatura
          };
          reader.readAsDataURL(file); // Convierte el archivo en una URL de datos base64
        }
      }
    }
  }
  async subirImagenesAFirebase(): Promise<string[]> {
    const urls = await Promise.all(
      this.selectedFiles.map(async (file:any, index) => {
        const fileName = `ticket_${Date.now()}_${index + 1}.jpg`;
        return this.subirImagenFirebase(file, fileName);
      })
    );
    return urls;
  }

  subirImagenFirebase(file: File, fileName: string): Promise<string> {
    const ref = this.storage.ref(`tickets/${fileName}`);
    const task = this.storage.upload(`tickets/${fileName}`, file);
  
    return new Promise((resolve, reject) => {
      task.snapshotChanges().pipe(
        finalize(() => {
          ref.getDownloadURL().subscribe({
            next: (url) => resolve(url),
            error: (err) => reject(err),
          });
        })
      ).subscribe({
        error: (error) => reject(error),
      });
    });
  }
  ngOnInit(): void {
    // Escuchar notificaciones en tiempo real
    this.notificationService.getNotifications().subscribe((data) => {
      this.notifications = data;
  
      // Mostrar alerta solo para notificaciones no leídas
      this.notifications.forEach((notification) => {
        if (!notification.read && !notification.show) {
          notification.show = true; // Marcar como mostrada en esta sesión
          // alert(`Nueva Notificación: ${notification.message}`);
  
          // Marcar la notificación como leída en la base de datos
          // this.notificationService.markAsRead(notification.id).then(() => {
          //   console.log(`Notificación ${notification.id} marcada como leída`);
          // });
        }
      });
    });
    // this.TicketService.getUsers().subscribe({
    //   next: (user:any) => {
      this.users = [{
        "id": "5",
        "nombreCompleto": "Jairo Arango",
        "iniciales": "JA",
        "color": "#e11919",
        "email": "jarango@almara.com",
        "celular": "3015380656",
        "fechaCreacion": "2024-11-06T13:16:04.0316182+00:00"
      }, {
        "id": "6",
        "nombreCompleto": "Julian Navarro",
        "iniciales": "JN",
        "color": "#0b15f0",
        "email": "jlbatty113@gmail.com",
        "celular": "3015380656",
        "fechaCreacion": "2024-11-06T13:16:04.0316182+00:00"
      }, {
        "id": "7",
          "nombreCompleto": "Daniel Garcia",
          "iniciales": "DG",
          "color": "#abf00b",
          "email": "danielmg12361@gmail.com",
          "celular": "3243564016",
          "fechaCreacion": "2024-11-06T13:16:04.0316182+00:00"
      },{
        "id": "8",
          "nombreCompleto": "Santiago Garcia",
          "iniciales": "SG",
          "color": "#f00bae",
          "email": "sgarcia@gmail.com",
          "celular": "3024218994",
          "fechaCreacion": "2024-11-06T13:16:04.0316182+00:00"
      }]; // Guardar categorías
    //   },
    //   error: (error) => {
    //     console.error('Error al obtener las categorías:', error);
       
    //   }
    // });
    
    console.log(this.users)
    console.log(this.task)
    this.comments = this.task.ticketComments || []; // Cargar los comentarios del ticket si existen
  }
   // Agregar nueva notificación
   addNotification() {
    this.notificationService.addNotification(this.newMessage, this.ticketId).then(() => {
      console.log('Notificación agregada');
      this.newMessage = '';
      this.ticketId = '';
    });
  }
  addNotificationKt(ticket:any) {
    console.log('ticket',ticket)
    this.notificationService.addNotificationKt("tu ticket: "+ticket.asunto+" ha sido actualizado", ticket.cd).then(() => {
      console.log('Notificación agregada');
      this.newMessage = '';
      this.ticketId = '';
    });
  }

  // Marcar como leída
  markAsRead(notificationId: string) {
    this.notificationService.markAsRead(notificationId).then(() => {
      console.log(`Notificación ${notificationId} marcada como leída`);
    });
  }

  // Eliminar notificación
  deleteNotification(notificationId: string) {
    this.notificationService.deleteNotification(notificationId).then(() => {
      console.log(`Notificación ${notificationId} eliminada`);
    });
  }
  @Input() task: any; // La estructura del ticket
  users: any[]; // Lista de usuarios para el campo "Responsable"
  @Input() isVisible: boolean; // Para controlar la visibilidad del modal
  @Input() comments: any[] = []; // Array para manejar los comentarios que vienen desde el padre
   // Lista de canales, reemplaza con los reales
  
  @Output() close = new EventEmitter<void>();
  @Output() updateTask = new EventEmitter<any>();
  @Output() addComment = new EventEmitter<string>(); // Emite el nuevo comentario hacia el componente padre
  newResponse: { [key: number]: string } = {};

  statuses: string[] = ['Pendiente','Haciendo', 'Resuelto', 'Archivado'];
  stateColors: any = {
    'Haciendo': '#f0ad4e',   // Color ámbar
    'Resuelto': '#5bc0de',   // Color azul claro
    'Pendiente': '#d9534f',
    'Archivado':'#969696'  // Color rojo
  };

  newComment: string = ''; 
  optionsVisible: boolean = false; 

  closeModal() {
    
    this.activeModal.close();
  }
 
  saveChanges() {
    // Lógica para guardar los cambios del ticket
    const updatedTicket = {
      cd:this.task.cd,
      nroTicket: this.task.nroTicket,
      canal: this.task.canal,
      pais: this.task.pais,
      tienda: this.task.tienda,
      categoria: this.task.categoria,
      subcategoria: this.task.subcategoria,
      nombreUsuarioReporta: this.task.nombreUsuarioReporta,
      fechaRegistro: this.task.fechaRegistro,
      fechaEvento: this.task.fechaEvento,
      asunto: this.task.asunto,
      adjuntos: this.task.adjuntos || [],
      usuarioMesaAyuda: this.users.find(user => user.nombreCompleto === this.task.usuarioMesaAyuda.nombreCompleto),
      ticketComments: this.comments || [],
      historyStatus: this.task.historyStatus || [],
      status: this.task.status,
      prioridad: this.task.prioridad
    };
  
    this.TicketService.editTicket(updatedTicket).subscribe({
      next: (response) => {
        
        if(this.imageBase64){
          const soporteCom:any={
            Ticket:updatedTicket,
            TicketComment:updatedTicket.ticketComments[updatedTicket.ticketComments.length-1],
            Base64String:this.imageBase64.split(',')[1]
          }
          this.TicketService.updateImageComents(soporteCom).subscribe({
            next:(response2)=>{
              console.log("respuesta",response2)
            },
            error:(erro) =>{
              console.error('error actualizando la imagen del comentario:',erro);
            }
          })
        }
        if(this.imageBase642){
          this.TicketService.updateImageResponse(this.responseComSupport).subscribe({
            next:(res)=>{
              console.log("respuesta",res)
            },
            error:(erro) =>{
              console.error('error actualizando la imagen de la respuesta:',erro);
            }
          })
        }
        
         Swal.fire('Ok','Ticket actualizado con exito','success')
        this.addNotificationKt(updatedTicket)
        this.activeModal.close()
      },
      error: (err) => {
        console.error('Error actualizando el ticket:', err);
      }
    });
  }
  saveChangesButton() {
    // Lógica para guardar los cambios del ticket
    const updatedTicket = {
      cd:this.task.cd,
      nroTicket: this.task.nroTicket,
      canal: this.task.canal,
      pais: this.task.pais,
      tienda: this.task.tienda,
      categoria: this.task.categoria,
      subcategoria: this.task.subcategoria,
      nombreUsuarioReporta: this.task.nombreUsuarioReporta,
      fechaRegistro: this.task.fechaRegistro,
      fechaEvento: this.task.fechaEvento,
      asunto: this.task.asunto,
      adjuntos: this.task.adjuntos || [],
      usuarioMesaAyuda: this.users.find(user => user.nombreCompleto === this.task.usuarioMesaAyuda.nombreCompleto),
      ticketComments: this.comments || [],
      historyStatus: this.task.historyStatus || [],
      status: this.task.status,
      prioridad: this.task.prioridad
    };
  
    this.TicketService.editTicket(updatedTicket).subscribe({
      next: (response) => {
        console.log('Ticket actualizado:', response);
        
        if(this.imageBase64){
          const soporteCom:any={
            Ticket:updatedTicket,
            TicketComment:updatedTicket.ticketComments[length],
            string:this.imageBase64
          }
          this.TicketService.updateImageComents(soporteCom).subscribe({
            next:(response2)=>{
              console.log(response2)
            },
            error:(erro) =>{
              console.error('error actualizando la imagen del comentario:',erro);
            }
          })
        }
        if(this.imageBase642){
          this.TicketService.updateImageResponse(this.responseComSupport).subscribe({
            next:(res)=>{
              console.log(res)
            },
            error:(erro) =>{
              console.error('error actualizando la imagen de la respuesta:',erro);
            }
          })
        }
        this.addNotificationKt(updatedTicket)
         Swal.fire('Ok','Ticket actualizado con exito','success')
        
      },
      error: (err) => {
        console.error('Error actualizando el ticket:', err);
      }
    });
  }

  // Método para añadir comentarios
  async addNewComment() {
    if (this.newComment.trim()) {
      // Obtener el id máximo de los comentarios existentes
      const maxId = this.comments.length > 0 ? Math.max(...this.comments.map(c => c.id)) : 0;
  
      // Obtener el usuario actual (puedes reemplazar esto por la lógica adecuada)
      const currentUserEmail = JSON.parse(localStorage.getItem('user')).email;
      let author = this.users.find(user => user.email === currentUserEmail);
  
      // Si no se encuentra el usuario, asignar un usuario genérico
      if (!author) {
        author = {
          id: '0',
          nombreCompleto: 'Desconocido',
          iniciales: 'UD',
          color: '#808080',
          email: 'anonimo@anonimo.com',
          celular: '0000'
        };
      }
  
      // Sube las imágenes a Firebase y obtiene las URLs
      const adjuntosUrls = await this.subirImagenesAFirebase();
  
      // Crear el nuevo comentario
      const newCommentObj = {
        id: maxId + 1,
        contenido: this.newComment,
        fechaCreacion: new Date().toISOString(),
        autor: author,
        ticketId: this.task.cd,
        estado: 1,
        respuestas: [],
        ultimaModificacion: new Date().toISOString(),
        version: 1,
        adjuntos: adjuntosUrls // Aquí se agregan las URLs de los adjuntos
      };
  
      // Añadir el nuevo comentario a la lista
      this.comments.push(newCommentObj);
      this.task.ticketComments = [...this.comments];
  
      // Limpia el campo de texto después de agregar el comentario
      this.newComment = '';
      this.imagePreviews = [];
      this.selectedFiles = [];
  
      // Guarda los cambios en el ticket
      this.saveChanges();
    }
  }
  
  toggleOptions() {
    this.optionsVisible = !this.optionsVisible; // Alterna la visibilidad
  }

  selectStatus(status: string, event: MouseEvent) {
    event.stopPropagation(); // Evita que el clic se propague
    this.task.status = status; // Actualiza el estado del ticket
    this.optionsVisible = false; // Cierra las opciones
  }
  toggleRespuestas(comment: any,event: MouseEvent) {
    event.stopPropagation()
    // Alterna el valor de mostrarRespuestas para mostrar u ocultar respuestas
    comment.mostrarRespuestas = !comment.mostrarRespuestas;
  }
  addNewResponse(comment: any) {
    const responseContent = this.newResponse[comment.id]?.trim(); // Obtener la nueva respuesta

    if (responseContent) {
      // Obtener el id máximo de las respuestas existentes
      const maxIdRespuesta = comment.respuestas.length > 0 ? Math.max(...comment.respuestas.map(r => r.id)) : 0;
      
      // Asignar el autor de la respuesta (deberías obtener el usuario actual de algún servicio)
      const currentUserEmail = JSON.parse(localStorage.getItem('user')).email; // Puedes usar el email del usuario actual del sistema
      let author = this.users.find(user => user.email === currentUserEmail);

      // Si no se encuentra el usuario, asignar un autor genérico
      if (!author) {
        author = {
          id: '0',
          nombreCompleto: 'Desconocido',
          iniciales: 'UD',
          color: '#808080', // Color gris
          email: 'anonimo@anonimo.com',
          celular: '0000'
        };
      }

      // Crear la nueva respuesta
      const newResponseObj = {
        adjuntos:[],
        id: maxIdRespuesta + 1, // Incrementa el id basado en el id mayor existente
        contenido: responseContent,
        fechaCreacion: new Date().toISOString(), // Fecha de creación en formato ISO
        autor: author, // Autor de la respuesta
        estado: 1, // Estado de la respuesta
        ultimaModificacion: new Date().toISOString(),
        version: 1 // Versión inicial
      };

      // Añadir la nueva respuesta al array de respuestas del comentario
      comment.respuestas.push(newResponseObj);
      
      if(this.imageBase642){
        this.responseComSupport={
          Ticket:this.task,
          TicketComment:comment,
          TicketReplayComment:newResponseObj,
          Base64String :this.imageBase642.split(',')[1],
          IsNew:true
        }
        
      }
      
      // Limpia el campo de texto de respuesta
      this.newResponse[comment.id] = '';
      this.saveChanges()
    }
  }
  onFileSelected2(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      this.selectedFiles = []; // Reiniciar si se seleccionan nuevos archivos
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        
        // Crear una vista previa de la imagen
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const base64String2 = e.target.result; // Este es el string en base64

          // Almacenar el string Base64 de la imagen en una variable global
          this.imageBase642 = base64String2;

          const obj: any = {
            file: file,  // El archivo 'File'
            preview: base64String2 // Vista previa en Base64
          };
        
          this.selectedFiles.push(obj);
        };
        reader.readAsDataURL(file);
      }
    }
  }
}
