import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { StorageService } from '../../service/storage.service';
import { TicketService } from '../../service/ticket.service';  // Importa tu servicio de tickets
import { NotificationService } from '../../service/rtdatabase.service';
import Swal from 'sweetalert2';
import { TicketDetailsComponent } from 'src/app/components/backlog-tickets/ticket-details/ticket-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;
  public assignedTicketsCount: number = 0; // Contador de tickets asignados
  public currentUserEmail: string = ''; // Correo electrónico del usuario actual
  public newTickets: any[] = []; // Lista de tickets nuevos o pendientes
  public unreadNotifications: any[] = []; // Lista de notificaciones no leídas

  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  

  constructor(
    private modalService: NgbModal,
    public navServices: NavService,
    private storageService: StorageService,
    private notificationService: NotificationService // Inyecta el servicio de tickets
  ) { }

  // Método para obtener el correo electrónico del usuario actual
  getCurrentUserEmail() {
    // Asegúrate de que tienes una manera de obtener el correo del usuario actual desde el almacenamiento o una API
    const user = 'jarango';  // Asume que tienes el usuario guardado en el almacenamiento
    if (user ) {
      this.currentUserEmail = 'jarango@almara.com';
    }
  }

  // Método para cargar los tickets y comparar
  loadTickets() {
    
    // this.ticketService.getTickets().subscribe((tickets: any) => {
    //   this.ticketService.getUsers().subscribe((users: any) => {
    //     // Encuentra al usuario actual basado en su correo electrónico
    //     const currentUser = users.result.find(user => user.email === this.currentUserEmail);
    //     if (currentUser) {
    //       // Filtrar los tickets asignados a este usuario
    //       const assignedTickets = tickets.result.filter(ticket => ticket.usuarioMesaAyuda.email === currentUser.email);
    //       this.assignedTicketsCount = assignedTickets.length;  // Asignar el conteo de tickets
    //     }
    //   });
    // });
    // this.ticketService.getTicketByUser({'email':JSON.parse(localStorage.getItem('msauc_user')).companyMail}).subscribe((tickets1: any) => {
    //   console.log(tickets1)
    //   this.newTickets=tickets1.result
    // })
  }
   // Cargar notificaciones desde el servicio
   loadNotifications() {
    this.notificationService.getNotifications().subscribe((notifications) => {
      this.unreadNotifications = notifications.filter((n) => !n.read); // Filtra las no leídas
      this.newTickets = notifications; // Lista completa de notificaciones
    });
  }
// Marcar una notificación como leída
markNotificationAsRead(notificationId: string) {
  this.notificationService.markAsRead(notificationId).then(() => {
    console.log(`Notificación ${notificationId} marcada como leída`);
    this.loadNotifications(); // Recarga la lista después de actualizar
  });
}

openTaskDetails(task: any, notificationid:any) {
  const modalRef = this.modalService.open(TicketDetailsComponent, { size: 'xl', fullscreen: false });
  modalRef.componentInstance.task = task;

  modalRef.result.then(
    (updatedTask) => {
      this.markNotificationAsRead(notificationid)
      // Si hay un `updatedTask`, significa que se guardaron cambios, entonces se ejecuta ngOnInit()
      if (updatedTask) {
        console.log('Ticket actualizado:', updatedTask);
        this.ngOnInit();
      }
    },
    (reason) => {
      // Si el modal se cerró sin cambios, puedes verificar `reason`
      if (reason !== 'backdrop click' && reason !== 'dismissed') {
        console.log('Modal cerrado sin guardar cambios');
      }
    }
  );
}


// Eliminar una notificación
deleteNotification(notificationId: string) {
  this.notificationService.deleteNotification(notificationId).then(() => {
    console.log(`Notificación ${notificationId} eliminada`);
    this.loadNotifications(); // Recarga la lista después de eliminar
  });
}


  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  logout() {
    this.storageService.removeItem('msauc_user');
  }

  ngOnInit() {
    this.getCurrentUserEmail(); // Cargar el correo del usuario actual
    this.loadNotifications(); // Cargar las notificaciones iniciales

  }
}
